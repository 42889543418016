import { css } from 'styled-components';

import { fontStyles } from '../constants';

export const BaseStyles = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    &::before,
    &::after {
      content: none; // nedded to reliably reset pseudo elements in Safari
    }
  }

  html,
  body {
    font-family: var(${fontStyles.text.variableName}, arial);
    font-feature-settings:
      'zero' on,
      'ss01' on,
      'cv05' on;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 170%;
    margin: 0;
    padding: 0;
    text-rendering: optimizeSpeed;
  }

  a,
  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    border: none;
    outline: none;
  }

  b,
  strong {
    font-weight: 600;
  }
`;
