import { TrackingContextValue } from '@lichtblick/tracker';

import { ReactComponentNames, getComponentDomElements } from './reactComponentsInitializer';

import { allInOneCalculatorTrackingInitializer } from '../tracking/allInOneCalculatorTrackingInitializer';
import { formTrackingInitializer } from '../tracking/formsTrackingInitializer';
import { permissionCenterTrackingInitializer } from '../tracking/permissionCenterTrackingInitializer';

const TrackingInitializerMap: {
  [name in ReactComponentNames]?: (tracker: TrackingContextValue['trackCustomEvent']) => () => void;
} = {
  AllInOneCalculator: allInOneCalculatorTrackingInitializer,
  PermissionCenter: permissionCenterTrackingInitializer,
  LeadForm: formTrackingInitializer,
};

export const initTracking = (tracker: TrackingContextValue['trackCustomEvent']) => {
  const componentDomElements = getComponentDomElements();
  const componentsToInit = Object.keys(componentDomElements) as ReactComponentNames[];

  const cleanUpFunctions = componentsToInit.map((componentName) => TrackingInitializerMap[componentName]?.(tracker));

  return () => {
    cleanUpFunctions.map((cleanUpFunction) => cleanUpFunction?.());
  };
};
