export const CONSENT_STATUS_CHANGE = 'UC_CONSENT';
export const UC_UI_INITIALIZED = 'UC_UI_INITIALIZED';

export const GOOGLE_MAPS_NAME = 'Google Maps';
export const GOOGLE_PLACES_NAME = 'Google Maps Places';
export const GOOGLE_ANALYTICS_NAME = 'Google Analytics';
export const GOOGLE_TAG_MANAGER_NAME = 'Google Tag Manager';
export const GOOGLE_ADS_NAME = 'Google Ads';
export const GOOGLE_CUSTOMER_MATCH_NAME = 'Google Customer Match';
export const YOUTUBE_NAME = 'YouTube Video';
export const AKLAMIO_NAME = 'Aklamio';
export const DATADOG_RUM_NAME = 'Datadog Real User Monitoring';
export const OPTIMIZELY_NAME = 'Optimizely';
export const HOTJAR_NAME = 'Hotjar';
export const FACEBOOK_NAME = 'Facebook Pixel';
export const LINKEDIN_NAME = 'LinkedIn Ads';

export type ServiceConsent = {
  consent?: boolean;
  essential: boolean;
};

export type ServicesConsent = {
  [key: string]: ServiceConsent;
};
export type UserCentricsServiceName =
  | typeof GOOGLE_MAPS_NAME
  | typeof GOOGLE_PLACES_NAME
  | typeof GOOGLE_ANALYTICS_NAME
  | typeof GOOGLE_TAG_MANAGER_NAME
  | typeof GOOGLE_ADS_NAME
  | typeof GOOGLE_CUSTOMER_MATCH_NAME
  | typeof YOUTUBE_NAME
  | typeof AKLAMIO_NAME
  | typeof OPTIMIZELY_NAME
  | typeof DATADOG_RUM_NAME
  | typeof HOTJAR_NAME
  | typeof FACEBOOK_NAME
  | typeof LINKEDIN_NAME;

export type ConsentManagerType = {
  initPromise: Promise<void> | null;
  isUcInitialized: boolean;
  getConsentStatus(serviceName: string): Promise<boolean | undefined>;
  getControllerId(): Promise<string | undefined>;
  init(): Promise<void>;
};

/* eslint-disable @typescript-eslint/member-ordering */
// Usercentrics types
// https://usercentrics.com/docs/web/features/api/interfaces/
// https://usercentrics.com/docs/web/features/api/types/
// https://usercentrics.com/docs/web/features/api/enums/

type SettingType = 'TCF' | 'GDPR' | 'CCPA';
type ConsentType = 'IMPLICIT' | 'EXPLICIT';

type SettingData = {
  id: string;
  type: SettingType;
  version: string;
  abVariant?: string;
  sandbox?: true;
};

type ConsentActionType =
  | 'onAcceptAllServices'
  | 'onDenyAllServices'
  | 'onEssentialChange'
  | 'onInitialPageLoad'
  | 'onNonEURegion'
  | 'onSessionRestored'
  | 'onTcfStringChange'
  | 'onUpdateServices'
  | 'onMobileSessionRestore';

type CategoryData = {
  essential?: boolean;
  state: 'ALL_DENIED' | 'SOME_ACCEPTED' | 'ALL_ACCEPTED';
  dps: Record<string, boolean> | null;
  hidden?: boolean;
};

export type ServiceData = {
  name: string;
  version: string;
  category: string;
  essential: boolean;
  consent?: {
    given: boolean;
    type: 'IMPLICIT' | 'EXPLICIT';
  };
  gcm?: {
    analyticsStorage?: true;
    adStorage?: true;
  };
  subservices?: Record<string, ServiceData>;
  thirdCountryDataTransfer?: boolean;
  status?: 'added';
};

type ConsentData = {
  status: 'ALL_ACCEPTED' | 'ALL_DENIED' | 'SOME_ACCEPTED' | 'SOME_DENIED';
  serviceIds?: string[];
  required: boolean;
  version: number;
  controllerId: string;
  language: string;
  createdAt: number;
  updatedAt: number;
  updatedBy: ConsentActionType;
  setting: SettingData;
  type: ConsentType;
  hash: string;
  gpcSignal?: boolean;
  isBot?: true;
  isOutsideEu?: true;
};

export type ConsentDetails = {
  consent: ConsentData;
  services: Record<string, ServiceData>;
  categories: Record<string, CategoryData>;
};

export type UsercentricsUiAPIV3 = {
  getConsentDetails(): Promise<ConsentDetails>;
  isInitialized(): Promise<boolean>;
  showSecondLayer(): Promise<void>;
  updateServicesConsents(serviceConsent: { id: string; consent: boolean }[]): Promise<void>;
  getControllerId(): Promise<string>;
};

export type UCConsentEvent = {
  detail: ConsentDetails;
} & Event;

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface WindowEventMap {
    UC_CONSENT: UCConsentEvent;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-unused-vars
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __ucCmp?: UsercentricsUiAPIV3;
  }
}
